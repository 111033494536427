
import logo from '../assets/images/logo.png';
import React  from 'react';


class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen : false,
      contactMeString : "Say Hello!"
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
  renderMenuButton(){
    if(this.state.isOpen){
      return(
        <path fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
      )
    }else{
      return (
        <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
      )
    }
  }
  renderMenuItems(){
    if(this.state.isOpen){
      return(
        <nav className="px-2 pt-2 pb-4 sm:flex sm:p-0 "  >
        <button className="flex items-center px-3 py-2 bg-purple hover:bg-purple-700 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent rounded-full">
        <a href = "mailto: chrvillaruel@gmail.com"  rel="noopener noreferrer"> {this.state.contactMeString}</a>
        </button>
      </nav>
      )
    }
  }
  render() {
    return (
      <header className=" sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3">
            <div className="flex items-center justify-between px-4 py-3 sm:p-0">
              <div>
                <img
                  src={logo}
                  width="50"
                  height="50"
                  className="align-top d-inline-block"
                  alt="React Bootstrap logo"
                />
              </div>
              <div className="sm:hidden">
                <button  onClick={() => this.setState({isOpen: !this.state.isOpen})}  type="button" className="block text-gray-500 hover:text-white focus:text-white focus:outline-none">
                  <svg className="h-6 w-6 fill-current text-purple-900" viewBox="0 0 24 24">
                    {this.renderMenuButton()}
                  </svg>
                </button>
              </div>
            </div>
            <nav className={`${this.state.isOpen ? 'block' : 'hidden' } px-2 pt-2 pb-4 sm:flex sm:p-0`}  >
            <button className="flex items-center mx-auto px-3 py-2 bg-purple hover:bg-purple-900 text-purple-900 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent rounded-full">
            <a href = "mailto: chrvillaruel@gmail.com"> {this.state.contactMeString}</a>
            </button>
          </nav>
          </header>
    );
  }
}
export default Nav;
