import React from 'react'

class Footer extends React.Component {

  render() {
    return (
      <div className="flex-wrap px-6 py-6 mx-auto text-center px-10 md:px-0 sm:px-0 xs:px-5 bg-gray-900 mt-3">
        <p className="text-white sm:pb-0">© 2022 Christian Villaruel</p>
      </div>
    )
  }
}

export default Footer
