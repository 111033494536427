import chanAvatar from '../assets/images/chan-avatar.svg';
import secondFold from '../assets/images/second-fold.svg';
import designer from '../assets/images/designer.svg';
import code from '../assets/images/code.svg';
import mentor from '../assets/images/mentor.svg';
import React  from 'react';


class AboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
        <div>
            <div className="flex mb-4  py-50 ">
                <div className="w-full  ">
                <div className="mt-5 text-center">
                <h1 className="text-2xl font-semibold md:text-3xl">Fullstack Software Engineer</h1>
                    <p className="px-5 mt-3 mt-5 text-sm">I design and code beautifully simple things, and I love what I do.</p>
                    <div className="mt-5">
                    <img
                        src={chanAvatar}
                        width="250"
                        height="250"
                        className="w-auto mx-auto my-24 d-inline-block"
                        alt="React Bootstrap logo"
                    />
                    <br />
                    <img
                        src={secondFold}
                        width="400"
                        height="250"
                        className="w-auto mx-auto d-inline-block"
                        alt="React Bootstrap logo"
                    />
                    </div>
                </div>
                </div>
            </div>

            <div className="flex mb-4 bg-purple-900 ">
                <div className="w-full  py-10 px-10  md:px-48 md:py-48 lg:px-48 lg:py-48  text-white text-center">
                <h1 className="font-semibold sm:p-5 md:p-0 font-size-12 lg:font-size-20 md:text-3xl ">Hello, I’m Christian. Nice to meet you.</h1>
                    <p  className="w-full sm:text-sm md:text-lg">Since beginning my journey as a software engineer, I've done remote work for agencies, corporate and collaborated with talented people to create digital products for both business and consumer use. I've done many projects using different programming languages for five years, and I delivered them successfully. I'm very optimistic that I can help you.
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap  ">
          <div className="flex  flex-col sm:w-1/2 px-5 py-5   md:w-1/3  lg:px-0 lg:py-0  xl:px-0 xl:py-0  ">
              <div className=" text-center border-solid">
                <div className=" rounded overflow-hidden shadow-lg py-5">
                      <img
                        src={designer}
                        width="50"
                        height="50"
                        className="mx-auto d-inline-block "
                        alt="React Bootstrap logo"
                      />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2">Designer</div>
                      <p className="text-gray-700 text-base">
                      I value simple and clean design patterns, and thoughtful interactions.
                      </p>
                    </div>
                    <div className="px-6 py-4">
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#UI & UX</span>
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#CSS</span>
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#Responsivess</span>
                    </div>
                  </div>
                </div>
          </div>
          <div className=" flex  flex-col px-5 py-5 sm:w-1/2 md:w-1/3  lg:px-0 lg:py-0   xl:px-0 xl:py-0 ">
            <div className=" text-center border-solid">
                    <div className=" rounded overflow-hidden shadow-lg py-5">
                          <img
                            src={code}
                            width="50"
                            height="50"
                            className="mx-auto d-inline-block "
                            alt="React Bootstrap logo"
                          />
                        <div className="px-6 py-4">
                          <div className="font-bold text-xl mb-2">Developer</div>
                          <p className="text-gray-700 text-base">
                          I like to code things from scratch, and enjoy bringing ideas to life in the browser.
                          </p>
                        </div>
                        <div className="px-6 py-4">
                          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#API</span>
                          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#Backend</span>
                          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#Fronted</span>
                        </div>
                      </div>
              </div>
          </div>
          <div className="flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3   lg:px-0 lg:py-0   xl:px-0 xl:py-0 ">
            <div className="text-center border-solid">
                <div className="rounded overflow-hidden shadow-lg py-5">
                      <img
                        src={mentor}
                        width="50"
                        className="mx-auto d-inline-block "
                        alt="React Bootstrap logo"
                      />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2">Mentor</div>
                      <p className="text-gray-700 text-base">
                      I genuinely care about people, and love helping fellow developers on their craft.
                      </p>
                    </div>
                    <div className="px-6 py-4">
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#help</span>
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#learn</span>
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">#build</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        </div>
    );
  }
}
export default AboutMe;
