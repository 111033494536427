import React from 'react';
import frameworks from '../assets/images/programming/frameworks.png';

class Skills extends React.Component {

  render () {
    return (
      <div>
        <div className="flex-wrap px-6 py-6 mx-auto text-center">
            <h1 className="font-semibold text-3xl pt-10">Skills</h1>
            <p className="md:pb-10 sm:pb-0">Here are a few frameworks I've worked on</p>
        </div>

        <div className="flex mb-10 bg-white">
            <div className="w-full">
                <img
                  src={frameworks}
                  className="mx-auto"
                  width="850"
                  alt="Framworks"
                />
            </div>
        </div>
      </div>
    )
  }

}

export default Skills;
