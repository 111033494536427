
import React  from 'react';
import excel from '../assets/images/projects/excel_logo.png';
import esl from '../assets/images/projects/esl.svg';
import laurus from '../assets/images/projects/laurus.png';
import videoscribe from '../assets/images/projects/videoscribe.png';
import sparkol from '../assets/images/projects/sparkol.png';
import powerplate from '../assets/images/projects/powerplate.png';

class Projects extends React.Component {

  render() {
    return (
    <div className="flex-wrap px-6 py-6 mx-auto text-center">
        <h1 className="font-semibold text-3xl pt-10">My Recent Work</h1>
        <p className="pb-10">Here are a few projects I've worked on recently. Want to see more? <a href = "mailto: chrvillaruel@gmail.com"   rel="noopener noreferrer" className="text-purple-900">Email me.</a> </p>
        <div className="flex flex-wrap  ">
          <div className="  flex  flex-col sm:w-1/2 px-5 py-5   md:w-1/3  h-auto">
            <a target="_blank" href="http://exceldrivingschool.com/"  rel="noopener noreferrer">
                <div className=" text-center border-solid">
                  <div className="bg-indigo-700 rounded overflow-hidden shadow-lg py-5">
                        <img
                          src={excel}
                          height="50"
                          width="200"
                          className="mx-auto d-inline-block "
                          alt="React Bootstrap logo"
                        />
                      <div className="px-6 py-4 text-white">
                          <div className="font-bold text-xl mb-2">EXCEL DRVIVING SCHOOL </div>
                          <p className="text-white text-base">
                          A driving school site in Chicago that allows the students to schedule sessions online.
                          </p>
                      </div>
                    </div>
                  </div>
              </a>
          </div>
          <div className=" flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3  h-auto">
          <a target="_blank" href="https://esllist.com/"  rel="noopener noreferrer">
            <div className="text-white text-center border-solid">
                    <div className="bg-blue-600 rounded overflow-hidden shadow-lg py-5">
                          <img
                            src={esl}
                            className="mx-auto d-inline-block "
                            alt="React Bootstrap logo"
                          />
                        <div className="px-6 py-4">
                          <div className="font-bold text-xl mb-2">ESLList</div>
                          <p className="text-white text-base">
                          A job listing and subscription site for mostly an English tutor in South Korea.
                          </p>
                        </div>

                      </div>
              </div>
              </a>
          </div>
          <div className="flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3 h-auto ">
          <a target="_blank" href="https://laurus.ph/"  rel="noopener noreferrer">
            <div className="text-center border-solid">
                <div className="bg-red-500  rounded  shadow-lg py-5">
                      <img
                        src={laurus}
                        width="100"
                        height="100"
                        className="mx-auto d-inline-block "
                        alt="React Bootstrap logo"
                      />
                    <div className="px-6 py-4">
                      <div className=" text-white font-bold text-xl mb-2">Laurus</div>
                      <p className="text-white text-base">
                      It is an online booking system for events for self improvement learn from best speakers.
                      </p>
                    </div>

                  </div>
                </div>


              </a>
            </div>

            <div className="flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3 h-auto   ">

            <a target="_blank" href="https://www.videoscribe.co/"  rel="noopener noreferrer">
              <div className="text-center border-solid">
                  <div className="bg-blue-400  rounded  shadow-lg py-5">
                        <img
                          src={videoscribe}
                          width="200"
                          height="200"
                          className="py-3 mx-auto d-inline-block "
                          alt="React Bootstrap logo"
                        />
                      <div className="px-6 py-4">
                        <div className=" text-white font-bold text-xl mb-2">Videoscribe</div>
                        <p className="text-white text-base">
                        Whiteboard style animated video, or scribe videos, then check out VideoScribe.
                        </p>
                      </div>

                    </div>
                  </div>
              </a>
            </div>


            <div className="flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3  h-auto   ">
            <a target="_blank" href="https://www.sparkol.com/en/"  rel="noopener noreferrer">
            <div className="text-center border-solid">
                <div className="bg-red-400  rounded  shadow-lg py-5">
                      <img
                        src={sparkol}
                        width="200"
                        height="200"
                        className="py-4 mx-auto d-inline-block "
                        alt="React Bootstrap logo"
                      />
                    <div className="px-6 py-4">
                      <div className=" text-white font-bold text-xl mb-2">Sparkol</div>
                      <p className="text-white text-base">
                      Sparkol is the creator of the #1 whiteboard animation software like VideoScribe
                      </p>
                    </div>

                  </div>
                </div>
              </a>
            </div>


            <div className="flex flex-col px-5 py-5 sm:w-1/2 md:w-1/3  xl:h-48   ">

            <a target="_blank" href="https://powerplate.com/"  rel="noopener noreferrer">

            <div className="text-center border-solid">
                <div className="bg-red-400  rounded  shadow-lg py-5">
                      <img
                        src={powerplate}
                        width="200"
                        height="200"
                        className="mx-auto d-inline-block "
                        alt="React Bootstrap logo"
                      />
                    <div className="px-6 py-4">
                      <div className=" text-white font-bold text-xl mb-2">Powerplate</div>
                      <p className="text-white text-base">
                      A big ecommerce site based in UK and US. They are selling gym equipment worldwide.
                      </p>
                    </div>

                  </div>
                </div>
              </a>
            </div>
        </div>
      </div>)
  }
}


export default Projects;
