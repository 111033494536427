import React from 'react';
import {Helmet} from "react-helmet";
import Nav from './components/Nav';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';


import './assets/styles/main.css';
import Skills from './components/Skills';
import Footer from './components/Footer';


function App() {


  return (
    <div className="antialiased App " >
       <Helmet>
          <meta charSet="utf-8" />
          <title>Christian Viilaruel | Software Engineer</title>
        </Helmet>
        <Nav />
        <AboutMe />
        <Projects />
        <Skills />
        <Footer />
    </div>
  );
}

export default App;
